import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import NoDrugsFound from 'ui-kit/icons/no-drugs-found/no-drugs-found';

import { formatLocationAddress } from 'state/discount-card/discount-card.helpers';
import { discountCardUserLocationSelector } from 'state/discount-card/discount-card.selector';

import { PrescriptionFormProps } from 'types/discount-card';

import { convertToTitleCase } from 'util/string';

import withSkeleton from 'hoc/withSkeleton';

import DrugSearchForm from '../drug-form';
import './prescription-form.styles.scss';

const PrescriptionForm: React.FC<PrescriptionFormProps> = ({
    drugInfo,
    drugForm,
    showModal,
    onMoreDrugInfoClick,
    onPrescriptionChange
}) => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState(false);

    const locationSelector = useSelector(discountCardUserLocationSelector);
    const formatAddress = formatLocationAddress(locationSelector?.address, true);

    const { drugOptions } = drugForm || {};
    const selectedDrug = drugOptions?.find((f) => f.IsSelected);
    const selectedBrandDrug = drugOptions?.find((f) => f.BrandGeneric === 'B') || undefined;
    const selectedGenericDrug = drugOptions?.find((f) => f.BrandGeneric === 'G') || undefined;

    return (
        <div className="prescription-form">
            <div className="prescription-form-drug-info">
                <div className="name">
                    <div className="pill-image">
                        {drugInfo?.image ? (
                            <img
                                alt={drugInfo?.brandName}
                                src={`data:image/jpeg;base64,${drugInfo?.image}`}
                                draggable="false"
                            />
                        ) : (
                            <NoDrugsFound />
                        )}
                    </div>
                    <div className="name-details">
                        <div className="drug-name">
                            <div className="title">{convertToTitleCase(selectedDrug?.DrugName || '')}</div>
                            <div className="subtitle">
                                {selectedDrug?.BrandGeneric === 'G' && selectedBrandDrug && (
                                    <div className="text">
                                        {t('pages.discountCard.pharmacyResults.prescriptionForm.genericName', {
                                            brandName: convertToTitleCase(selectedBrandDrug?.DrugName || '')
                                        })}
                                    </div>
                                )}

                                {selectedDrug?.BrandGeneric === 'B' && selectedGenericDrug && (
                                    <div className="text">
                                        {t('pages.discountCard.pharmacyResults.prescriptionForm.brandName', {
                                            brandName: convertToTitleCase(selectedGenericDrug?.DrugName || '')
                                        })}
                                    </div>
                                )}

                                {!drugInfo?.description && (
                                    <div className="no-drug-description">
                                        {t('pages.discountCard.pharmacyResults.prescriptionForm.noDrugDescription')}
                                    </div>
                                )}

                                {showModal && (
                                    <Button
                                        label={t('pages.discountCard.pharmacyResults.prescriptionForm.link')}
                                        variant={'text-blue'}
                                        dataGALocation={'PharmacyResults'}
                                        onClick={onMoreDrugInfoClick}
                                        type={'button'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {drugInfo?.description && <div className="description">{drugInfo?.description}</div>}

                <ul>
                    {drugInfo?.dosage && (
                        <li>
                            <span>{drugInfo.dosage}</span>
                        </li>
                    )}
                    {drugInfo?.quantity && (
                        <li>
                            <span>{drugInfo.quantity}</span>
                        </li>
                    )}
                    {formatAddress && (
                        <li>
                            <span>{formatAddress}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="prescription-form-edit-prescription">
                <div
                    tabIndex={0}
                    role="button"
                    className="data"
                    onClick={() => setIsEditMode((state) => !state)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            event.preventDefault();
                            setIsEditMode((state) => !state);
                        }
                    }}
                >
                    <p>{t('pages.discountCard.pharmacyResults.prescriptionForm.editPrescription')}</p>
                    <ChevronIcon direction={isEditMode ? 'up' : 'down'} />
                </div>

                {isEditMode && <DrugSearchForm drug={drugForm} onChange={onPrescriptionChange} />}
            </div>
        </div>
    );
};

export default withSkeleton(PrescriptionForm);
