/* eslint-disable no-unused-vars */
export interface DiscountCardProps {
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
}

export interface SearchResultsProps {
    pharmacy?: PharmacyInfo;
    pharmacies?: PharmacyResults[];
    onSelectedPharmacy?: (pharmacy: PharmacyInfo) => void;
    selectedPharmacies?: object | null;
}

export interface DrugInfoProps {
    genericName?: DrugInfo['GenericName'];
    brandName?: DrugInfo['BrandName'];
    dosage?: string;
    quantity?: string;
    location?: string;
    image?: string;
    description?: string;
    ndc?: string;
    brandGeneric?: string;
}
export interface PrescriptionFormProps {
    drugInfo?: DrugInfoProps;
    drugForm?: DrugFormOptions;
    showModal?: boolean;
    onPrescriptionChange: (fieldValue: string, fieldType: DrugEditFormFieldsEnum) => void;
    onMoreDrugInfoClick: () => void;
}

export type PharmacyListProps = SearchResultsProps;

export interface PharmacyResults {
    name: string;
    price: string;
    address?: string;
    resultsNumber?: number;
    pharmacy: PharmacyInfo;
    locations: Array<PharmacyInfo>;
    pharmacy?: Omit<PharmacyResults, 'onLocationClick' | 'onSelectedPharmacy'>;
    isLowestPrice?: boolean;
    onLocationClick?: () => void;
    onSelectedPharmacy?: (pharmacy: object | undefined) => void;
}
export interface PharmacyInfo extends Drug {
    name: string;
    address: string;
    schedules: string;
    price?: string;
    phoneNumber?: string;
    isLowestPrice?: boolean;
    isSelected?: boolean;
    onCardClick?: (info: PharmacyInfo) => void;
}

// Props to be defined after maps integration
export interface UserLocation {
    address?: USPSPartialAddress;
    location?: unknown;
}

export interface LocationFilters {
    radiusRange: number | undefined;
}

export interface USPSPartialAddress {
    city: string | undefined;
    state: string | undefined;
    zip: string | undefined;
}
// Props to be defined after maps integration
export interface SearchPrescriptionPayload {
    drugName: string;
    location: unknown;
}
export interface SearchPrescriptionResponse {
    Data: SearchPrescriptionPayload;
    Errors: string[];
    Success: boolean;
    Message?: string;
}

export interface ScriptSaveAuthTokenResponse {
    tokenType: string;
    expiresIn: number;
    extExpiresIn: number;
    accessToken: string;
}

export interface ScriptSaveAutocompleteResponse {
    DrugNames: Record<'DrugName', string>[];
}

export interface ScriptSaveGetAutocompleteValuesPayload {
    search: string;
    count: number;
    authToken: string;
}
export interface searchDrugsByNameParams {
    drugName?: string;
    lat?: string;
    lng?: string;
    zipCode?: string;
    brandIndicator?: string;
    GSN?: string;
    includeDrugInfo?: boolean;
    includeDrugImage?: boolean;
    qty?: string;
    numPharm?: string;
    useUC?: boolean;
    priceOnly?: boolean;
    referencedBN?: string;
}

export interface Drug {
    ChainCode: string;
    PharmacyName: string;
    Latitude: number;
    Longitude: number;
    Address: string;
    Phone: string;
    Distance: number;
    City: string;
    State: string;
    Zip: string;
    HoursOfOperation: string;
    GroupName: string;
    NDC: string;
    LN: string;
    Qty: number;
    Price: number;
    PriceBasis: string;
    UCPrice: number;
    AWPPrice: number | null;
    MACPrice: number | null;
    BrandGeneric: string;
    DrugRanking: number;
    QuantityRanking: number;
    NCPDP: string;
    GroupNum: string;
    GSN: string;
    Splittable: boolean;
}

export enum DrugEditFormFieldsEnum {
    // eslint-disable-next-line no-unused-vars
    Brand = 'brand',
    // eslint-disable-next-line no-unused-vars
    Form = 'form',
    // eslint-disable-next-line no-unused-vars
    Dosage = 'dosage',
    // eslint-disable-next-line no-unused-vars
    Quantity = 'quantity'
}

export interface DrugName {
    DrugName: string;
    BrandGeneric: string;
    IsSelected: boolean;
}

export interface DrugQuantity {
    GSN: string;
    Quantity: number;
    QuantityLabel: string;
    Ranking: number;
    IsSelected: boolean;
}

export interface DrugForm {
    GSN: string;
    Form: string;
    Ranking: number;
    IsSelected: boolean;
    LN: string | null;
    CommonQty: number;
    IsDiscontinued: boolean;
    DiscontinuedDate: string | null;
}

export interface DrugStrength {
    GSN: string;
    Strength: string;
    Ranking: number;
    IsSelected: boolean;
    LN: string | null;
    CommonQty: number;
}

export interface DrugInfo {
    GenericName: string;
    BrandName: string;
    Description: string;
    GSN: string;
    NDC: string;
    PillImage: string;
    PillImageLastUpdated: string;
}

export interface DrugSearchResponse {
    Drugs: Drug[];
    Names: DrugName[];
    Quantities: DrugQuantity[];
    Forms: DrugForm[];
    Strengths: DrugStrength[];
    DrugInfo?: DrugInfo;
}

export interface DrugFormOptions {
    drugOptions: DrugSearchResponse['Names'];
    formOptions: DrugSearchResponse['Forms'];
    strengthOptions: DrugSearchResponse['Strengths'];
    quantityOptions: DrugSearchResponse['Quantities'];
}

export interface SendSmsEmailCardPayload {
    communicationAddress: string;
    name: string;
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
}

export interface DiscountCardResponse {
    groupID: string;
    cardholderID: string;
    rxBIN: string;
    rxPCN: string;
}

export interface DrugFormStrengthResponse {
    DrugName: string;
    BrandGeneric: string;
    Forms: DrugForm[];
    Strengths: DrugStrength[];
    Quantities: DrugQuantity[];
}
