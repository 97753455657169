// PharmacyList.tsx
import React, { useCallback, useMemo, useState } from 'react';

import { PharmacyInfo, PharmacyListProps, PharmacyResults } from 'types/discount-card';

import PharmacyDetails from '../pharmacy-details';
import './pharmacy-list.styles.scss';

const findLowestPricePharmacy = (pharmacies?: PharmacyResults[]) => {
    if (!pharmacies || pharmacies?.length === 0) return null;

    return pharmacies?.reduce((lowest, pharmacy) => {
        const currentPrice = parseFloat(pharmacy?.price);
        if (lowest === null || currentPrice < parseFloat(lowest.price)) {
            return pharmacy;
        }
        return lowest;
    }, null as PharmacyResults | null);
};

const PharmacyList: React.FC<PharmacyListProps> = ({ pharmacies, onSelectedPharmacy }) => {
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
    const [openGroupId, setOpenGroupId] = useState<string | null>(null);
    const lowestPricePharmacy = findLowestPricePharmacy(pharmacies);
    const handleGroupToggle = useMemo(
        () => (groupId: string) => {
            setOpenGroupId((prevOpenGroupId) => (prevOpenGroupId === groupId ? null : groupId));
        },
        []
    );

    const handleCardClick = useCallback(
        (pharmacy: PharmacyInfo) => {
            if (selectedCardId === pharmacy.NCPDP) {
                setSelectedCardId(null);
            } else {
                setSelectedCardId(pharmacy.NCPDP);
            }
            if (onSelectedPharmacy) {
                onSelectedPharmacy(pharmacy);
            }
        },
        [selectedCardId, onSelectedPharmacy]
    );

    return (
        <div className="pharmacy-list">
            {pharmacies?.map((pharmacy, index) => (
                <PharmacyDetails
                    key={index}
                    name={pharmacy?.name}
                    price={pharmacy?.price}
                    resultsNumber={pharmacy?.resultsNumber}
                    pharmacy={pharmacy}
                    locations={pharmacy?.locations}
                    isLowestPrice={lowestPricePharmacy !== null && pharmacy === lowestPricePharmacy}
                    onSelectedPharmacy={(pharmacy) => handleCardClick(pharmacy)}
                    selectedCardId={selectedCardId}
                    isOpen={openGroupId === pharmacy.name}
                    onGroupToggle={() => handleGroupToggle(pharmacy.name)}
                />
            ))}
        </div>
    );
};

export default PharmacyList;
