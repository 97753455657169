// Icons
import PinIcon from 'assets/icons/maps/pin.png';
import SelectedPinIcon from 'assets/icons/maps/selected-pin.png';
import { MAPBOX_PUBLIC_TOKEN } from 'gatsby-env-variables';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
// Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, Marker, NavigationControl, Popup } from 'react-map-gl';
import type { MapRef } from 'react-map-gl';

// Ui-Kit
import Button from 'ui-kit/button/button';
import MapViewIcon from 'ui-kit/illustrations/map-view/map-view';

// Utils
import { createGeoJSON, formatLocationData, setBounds, translateToAddressesData } from 'util/mapbox';

import './map-view.styles.scss';

/**
 * Mapbox GL JS
 */
mapboxgl.accessToken = MAPBOX_PUBLIC_TOKEN;
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

// Default Zoom (add'l "levels" depending on search radius)
const MAPBOX_INITIAL_ZOOM = 11;
const MAPBOX_ZOOM_LEVEL_0 = 14;
const MAPBOX_ZOOM_LEVEL_1 = 12;

/**
 * Renders a map view component that displays a list of addresses as markers on a map.
 *
 * @param {Object} addressesData - An array of objects containing address information.
 * @return {JSX.Element} A React component that displays a map view.
 */
const MapView = ({ selectedPharmacies, pharmacies, radius }) => {
    const mapRef = useRef<MapRef>();
    const imgRefs = useRef([]);
    const [lng, setLng] = useState(null);
    const [lat, setLat] = useState(null);
    const [zoom, setZoom] = useState(MAPBOX_INITIAL_ZOOM);
    const [isMapContainerVisible, setIsMapContainerVisible] = useState(false);
    const [geoJSON, setGeoJSON] = useState([]);
    const [popupInfo, setPopupInfo] = useState(null);
    const [viewState, setViewState] = React.useState({
        longitude: lng,
        latitude: lat,
        zoom: MAPBOX_INITIAL_ZOOM
    });

    const updateImageSrc = (index, newSrc) => {
        // Reset all imgRefs src to use PinIcon
        imgRefs.current.forEach((imgRef) => {
            if (imgRef) {
                imgRef.src = PinIcon;
            }
        });
        // update by index
        if (imgRefs.current[index]) {
            imgRefs.current[index].src = newSrc;
        }
    };

    const handleShowAllPharmacies = () => {
        setPharmacyData(pharmacies);
        setZoom(MAPBOX_INITIAL_ZOOM);
    };

    /**
     * Toggles the visibility of the map container.
     *
     * @return {void}
     */
    const toggleMapVisibility = () => {
        setIsMapContainerVisible((prevState) => !prevState);
    };

    function setPharmacyData(pharmacies) {
        const formattedAddresses = translateToAddressesData(pharmacies);

        // when no addresses are within the given radius
        if (Array.isArray(formattedAddresses) && !formattedAddresses.length) {
            setGeoJSON([]);
            return;
        }

        // create a feaureCollection (geoJSON)
        const featureCollection = createGeoJSON(formattedAddresses);
        setBounds(featureCollection, setLat, setLng);
        setGeoJSON(featureCollection);
    }

    useEffect(() => {
        if (lat && lng) {
            setBounds(geoJSON, setLat, setLng);
            setViewState({
                longitude: lng,
                latitude: lat,
                zoom
            });
        }
    }, [lat, lng, zoom, geoJSON]);

    useEffect(() => {
        if (pharmacies) {
            setPharmacyData(pharmacies);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pharmacies]);

    const togglePopup = useCallback((featureCollection) => {
        setPopupInfo(featureCollection);
    }, []);

    const handleSingleLocation = useCallback(
        (selectedPharmacies) => {
            const selectedAddress = [formatLocationData(selectedPharmacies)];
            const isMapped = geoJSON.features.filter((feature) => feature.properties.id === selectedAddress[0].id);
            const featureIndex = geoJSON.features.findIndex(
                (feature) => feature.properties.id === selectedAddress[0].id
            );

            // sets the marker as selected
            const featureCollection = createGeoJSON(selectedAddress);

            if (isMapped?.length > 0) {
                setZoom(MAPBOX_ZOOM_LEVEL_1);
                mapRef.current?.flyTo({ center: featureCollection?.features[0].geometry.coordinates });
            } else {
                // only has a single location to render
                setBounds(featureCollection, setLat, setLng);
                setGeoJSON(featureCollection);
                setZoom(MAPBOX_ZOOM_LEVEL_0);
            }

            // set active icon
            if (featureIndex || featureIndex === 0) {
                updateImageSrc(featureIndex, SelectedPinIcon);
            }

            // show popup
            togglePopup(featureCollection?.features[0]);
        },
        [geoJSON, mapRef, setLat, setLng, setGeoJSON, togglePopup, setZoom]
    );

    useEffect(() => {
        if (selectedPharmacies && isMapContainerVisible) {
            setPopupInfo(null); // Clear existing popups

            // List of pharmacy locations
            if (Array.isArray(selectedPharmacies?.locations)) {
                setPharmacyData([selectedPharmacies]);

                // single-selected pharmacy location w/formatted address
            } else {
                handleSingleLocation(selectedPharmacies);
            }
        }
    }, [selectedPharmacies, handleSingleLocation]);

    const markers = useMemo(
        () =>
            geoJSON?.features?.map((feature, index) => (
                <Marker
                    key={index}
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                    anchor="bottom"
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setPopupInfo(feature);
                    }}
                >
                    <img
                        ref={(el) => (imgRefs.current[index] = el)}
                        src={!selectedPharmacies || geoJSON?.features?.length > 1 ? PinIcon : SelectedPinIcon}
                        alt="Pin"
                        style={{ width: 24, height: 24 }}
                    />
                </Marker>
            )),
        [geoJSON, selectedPharmacies]
    );

    return isMapContainerVisible && lat && lng ? (
        <div className="map-container">
            <div className="sidebar">
                <span>Showing all pharmacies in your area</span>

                <div className="sidebar-actions">
                    <Button
                        className="btn-text btn-text-blue"
                        label="Hide Map View"
                        type="button"
                        variant={'text'}
                        onClick={() => {
                            toggleMapVisibility();
                        }}
                    />
                    {pharmacies && (
                        <Button
                            label={'Show All Pharmacies'}
                            type={'button'}
                            className="small-button btn btn-outline"
                            variant="outline-smoke"
                            onClick={handleShowAllPharmacies}
                        />
                    )}
                </div>
            </div>
            <Map
                id="displayMap"
                reuseMaps
                ref={mapRef}
                width="100%"
                height="100%"
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
            >
                <NavigationControl position="top-right" />

                {markers}

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={popupInfo.geometry.coordinates[0]}
                        latitude={popupInfo.geometry.coordinates[1]}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div className="popup-info">
                            <strong>{popupInfo.properties.name}</strong>
                            <br />
                            <span className="popup-info--content">{popupInfo.properties.address}</span>
                            <br />
                            <span className="popup-info--price">{popupInfo.properties.price}</span>
                        </div>
                    </Popup>
                )}
            </Map>
        </div>
    ) : (
        <div className="map-view">
            <MapViewIcon />
            <p>Show all the pharmacies on a map</p>

            <Button
                label={'Open Map View'}
                type={'button'}
                variant={'outline-primary'}
                className="small-button"
                onClick={toggleMapVisibility}
            />
        </div>
    );
};

export default MapView;
