import { navigate } from 'gatsby';
import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl';
import { useSelector } from 'react-redux';

import Link from 'ui-kit/link/link';

import PrescriptionErrorOrNoResults from 'components/prescription-error-or-no-results/prescription-error-or-no-results.component';

import { DiscountCardErrors } from 'state/discount-card/discount-card.helpers';
import {
    discountCardErrorSelector,
    discountCardLocationRadiusSelector
} from 'state/discount-card/discount-card.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { SearchResultsProps } from 'types/discount-card';

import storageHelper from 'util/storageHelper';

import useWindowDimensions from 'hooks/useWindowDimensions';

import withSkeleton from 'hoc/withSkeleton';

import CurrentLocation from '../current-location';
import DistanceRadius from '../distance-radius';
import MapView from '../map-view';
import PharmacyList from '../pharmacy-list';
import './search-results.styles.scss';

const SearchResultsError: React.FC<{
    hasNoDrugFounded: boolean;
    hasSearchError: boolean;
    t: TFunction;
    onNavigateError: () => void;
}> = ({ hasNoDrugFounded, hasSearchError, t, onNavigateError }) => (
    <div className="search-results-container-error">
        {hasNoDrugFounded && (
            <PrescriptionErrorOrNoResults
                title={t('pages.discountCard.pharmacyResults.searchResults.filterError.drugNotFound.title')}
                subTitle={t('pages.discountCard.pharmacyResults.searchResults.filterError.drugNotFound.subtitle')}
                variant="not-found"
            />
        )}
        {hasSearchError && (
            <PrescriptionErrorOrNoResults
                title={t('pages.discountCard.somethingWentWrong.title')}
                subTitle={t('pages.discountCard.somethingWentWrong.subTitle')}
                variant="error"
                buttonLabel={t('pages.discountCard.somethingWentWrong.tryAgain')}
                onNavigate={onNavigateError}
            />
        )}
    </div>
);

const SearchResults: React.FC<SearchResultsProps> = ({ selectedPharmacies, pharmacies, onSelectedPharmacy }) => {
    const { t } = useTranslation();

    const radiusSelector = useSelector(discountCardLocationRadiusSelector);
    const discountCardError = useSelector(discountCardErrorSelector);
    const hasSearchError =
        discountCardError && discountCardError?.messageText === DiscountCardErrors.somethingWentWrong;
    const hasNoDrugFoundedError =
        discountCardError && discountCardError.messageText === DiscountCardErrors.pharmaciesNotFound;
    const hasErrorSection = hasSearchError || hasNoDrugFoundedError;

    const { width } = useWindowDimensions();
    const isInnerWidthViewportRef = React.useRef<boolean>(false);

    useEffect(() => {
        if (width < BREAKPOINTS.lg) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    // get total pharmacies with all locations
    const totalPharmacyLocations = () => {
        return pharmacies?.reduce((total, pharmacy) => {
            return total + pharmacy.locations.length;
        }, 0);
    };

    const onNavigateError = () => {
        storageHelper.local.removeCurrentDrugInfo();
        navigate('/discount-card');
    };

    return (
        <div className="search-results">
            {!hasErrorSection && (
                <div className="search-results-header">
                    <h3>
                        {t('pages.discountCard.pharmacyResults.searchResults.header', {
                            pharmacyNumber: totalPharmacyLocations()
                        })}
                    </h3>

                    <div>
                        {t('pages.discountCard.pharmacyResults.searchResults.subheader')}{' '}
                        <Link
                            variant="underline-blue"
                            to={'/discount-card/get-your-card'}
                            label={t('pages.discountCard.pharmacyResults.searchResults.link')}
                            dataGALocation={'BirdiPharmacyResultsPage'}
                        />
                    </div>
                </div>
            )}
            <div className="search-results-container">
                {!hasSearchError && (
                    <div className="search-results-container-actions">
                        <CurrentLocation />
                        <DistanceRadius />
                    </div>
                )}

                {!hasErrorSection && (
                    <div className="search-results-container-content">
                        <MapProvider>
                            <div>
                                <PharmacyList pharmacies={pharmacies} onSelectedPharmacy={onSelectedPharmacy} />
                                <div className="search-results-container-content-disclaimer">
                                    {t('pages.discountCard.pharmacyResults.searchResults.disclaimer')}
                                </div>
                            </div>
                            {!isInnerWidthViewportRef.current && (
                                <MapView
                                    pharmacies={pharmacies}
                                    radius={radiusSelector}
                                    selectedPharmacies={selectedPharmacies}
                                />
                            )}
                        </MapProvider>
                    </div>
                )}

                {hasErrorSection && (
                    <SearchResultsError
                        hasNoDrugFounded={hasNoDrugFoundedError}
                        hasSearchError={hasSearchError}
                        t={t}
                        onNavigateError={onNavigateError}
                    />
                )}
            </div>
        </div>
    );
};

export default withSkeleton(SearchResults);
