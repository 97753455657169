import { WebMercatorViewport } from '@math.gl/web-mercator';

import { FormattedLocation, Location } from 'types/mapbox';

/**
 * Creates a GeoJSON object based on geocoded addresses and addresses data.
 *
 * @param {any} geocodedAddresses - Array of geocoded addresses
 * @return {Object} The GeoJSON object representing the address features
 */
export function createGeoJSON(geocodedAddresses) {
    return {
        type: 'FeatureCollection',
        features: geocodedAddresses.map((item, index) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: item.coordinates
            },
            properties: {
                id: item.id,
                name: item.name,
                address: item.address,
                price: item.price
            }
        }))
    };
}

export function formatLocationData(location: Location): FormattedLocation {
    const formattedLocation: FormattedLocation = {
        id: location.NCPDP,
        name: location.PharmacyName,
        coordinates: [location.Longitude, location.Latitude],
        address: `${location.Address}, ${location.City}, ${location.State} ${location.Zip}`,
        price: location.price
    };

    if (location.Distance) {
        formattedLocation.distance = location.Distance;
    }

    return formattedLocation;
}

export function translateToAddressesData(pharmacies: Location[]) {
    if (!pharmacies) return;

    const addressesData = [];
    pharmacies.forEach((pharmacy) => {
        pharmacy.locations.forEach((location) => {
            addressesData.push(formatLocationData(location));
        });
    });

    return addressesData;
}

export function setBounds(geoJSON, setLat, setLng, setZoom) {
    // calculate the bounding box of all markers and fit the map to the bounding box
    if (geoJSON.features.length > 0) {
        const coordinates = geoJSON.features.map((feature) => feature.geometry.coordinates);

        // Calculate the bounding box
        const bounds = coordinates.reduce(
            (acc, coord) => {
                return {
                    minLng: Math.min(acc.minLng, coord[0]),
                    maxLng: Math.max(acc.maxLng, coord[0]),
                    minLat: Math.min(acc.minLat, coord[1]),
                    maxLat: Math.max(acc.maxLat, coord[1])
                };
            },
            {
                minLng: Infinity,
                maxLng: -Infinity,
                minLat: Infinity,
                maxLat: -Infinity
            }
        );

        const viewport = new WebMercatorViewport().fitBounds(
            [
                [bounds.minLng, bounds.minLat],
                [bounds.maxLng, bounds.maxLat]
            ],
            {
                padding: 30,
                width: 650,
                height: 785
            }
        );
        setLat(viewport.latitude);
        setLng(viewport.longitude);
        // setZoom(viewport.zoom);
    }
}
